import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUsers, deleteUser } from "../../redux/features/userSlice";
import { RootState, AppDispatch } from "../../redux/store";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Avatar,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MasterTab from "./MasterTab";
import MasterSearch from "./MasterSearch";
import { styled } from "@mui/material/styles";
import Hepl from "../../assets/images/heplimg.png";
import { UserData } from "./AddUserForm";
import EditUserForm from "./EditUserForm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
  fontSize: "0.875rem",
  "&:last-child": {
    borderRight: "none",
  },
}));

const UserMasterTable: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserData | null>(null);
  const [selectedUserId, setSelectedUserId] = React.useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { users, status, error } = useSelector(
    (state: RootState) => state.user
  );

  // const token = localStorage.getItem("token")??null;
  console.log("users=====>", users.data);
  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchUsers());
    }
  }, [status, dispatch]);

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  const handleDeleteClick = (userId?: string) => {
    setSelectedUserId(userId || '');
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUserId(null);
  };

  const handleConfirmDelete = () => {
    if (selectedUserId) {
      dispatch(deleteUser(selectedUserId));
    }
    setOpenDialog(false);
  };

  const handleEditClick = (user: UserData) => {
    setSelectedUser(user);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setSelectedUser(null);
  };
 
  return (
    <Box sx={{ width: "auto", backgroundColor: "#f0f4ff", overflowX:"hidden"}} p={1}>
      <MasterTab
        tabIndex={tabIndex}
        onTabChange={(e, newIndex) => setTabIndex(newIndex)}
      />

      <MasterSearch />

      <TableContainer component={Paper} sx={{ mt: 2 ,width:"100%"}} className="table-view-x-scroll" >
        <Table 
        // sx={{ minWidth: 650 }} 
        aria-label="user master table">
          <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
            <TableRow>
              <StyledTableCell padding="checkbox">
                <input type="checkbox" />
              </StyledTableCell>
              <StyledTableCell>SI.No.</StyledTableCell>
              <StyledTableCell>Employee Details</StyledTableCell>
              <StyledTableCell>Designation</StyledTableCell>
              <StyledTableCell>Email Id</StyledTableCell>
              <StyledTableCell>Mobile Number</StyledTableCell>
              <StyledTableCell>Supervisor</StyledTableCell>
              <StyledTableCell>Department</StyledTableCell>
              <StyledTableCell>Role</StyledTableCell>
              <StyledTableCell>Organisation</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.data &&
              users.data.map((user: UserData, index: number) => (
                <TableRow key={user.id || index}>
                  <StyledTableCell padding="checkbox">
                    <input type="checkbox" />
                  </StyledTableCell>
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        src={user.profilePicture}
                        sx={{ width: 24, height: 24, mr: 1 }}
                      />
                      <Typography variant="body2">{user.firstName}</Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>{user.designation}</StyledTableCell>
                  <StyledTableCell>{user.email}</StyledTableCell>
                  <StyledTableCell>{user.mobNumb}</StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <Avatar src={user.supervisor.avatar} sx={{ mr: 1 }} /> */}
                      <Typography variant="body2">{user.supervisor}</Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>{user.department}</StyledTableCell>
                  <StyledTableCell>{user.role}</StyledTableCell>
                  <StyledTableCell>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={Hepl}
                        alt="HEPL img"
                        style={{ width: "50px", height: "auto" }}
                      />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton size="small" onClick={() => handleEditClick(user)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                      <IconButton size="small" onClick={() => handleDeleteClick(user.id ?? '')}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {selectedUser && (
        <EditUserForm
          open={editOpen}
          onClose={handleEditClose}
          user={selectedUser}
        />
      )}
    </Box>
  );
};

export default UserMasterTable;
