import {
  Menu as MenuIcon,
  Search,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Drawer,
  Hidden,
  IconButton,
  InputBase,
  Popover,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Sidebar from "./SideBar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { fetchProfile } from "../../../redux/features/profileSlice";
import { AppDispatch, RootState } from "../../../redux/store";

const SearchBar = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(3),
  width: "auto",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const NotificationDrawer: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleToggleDrawer}>
        <Badge badgeContent={1} color="secondary">
          <NotificationsIcon />
          {/* sx={{backgroundColor:'white', border: 'px solid white'}} */}
        </Badge>
      </IconButton>
      <Drawer anchor="right" open={open} onClose={handleToggleDrawer}>
        <div style={{ width: 300, padding: 16 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Notifications</Typography>
            <IconButton onClick={handleToggleDrawer}>
              <CloseIcon />
            </IconButton>
          </Box>
          <List>
            <ListItem>
              <ListItemText
                primary="Hi Scott (Team Head 1)"
                secondary="Sr. UX Designer (RTH) has approved by Michael (L1 Approver)"
              />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  );
};

const ProfileMenu: React.FC<{
  anchorEl: HTMLElement | null;
  onClose: () => void;
}> = ({ anchorEl, onClose }) => {
  const navigate = useNavigate();

  const handleProfileClick = () => {
    onClose(); 
    navigate("/profile"); 
  };

  const handleLogoutClick = () => {
    onClose(); 
    navigate("/login"); 
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <List>
        <ListItem button onClick={handleProfileClick}>
          <ListItemText primary="Profile Settings" />
        </ListItem>
        <ListItem button onClick={handleLogoutClick}>
          <ListItemText
            primary="Logout"
            primaryTypographyProps={{ color: "error" }}
          />
        </ListItem>
      </List>
    </Popover>
  );
};

const HeaderBar: React.FC = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const token: any = localStorage.getItem("token");
  const isMobile = !useMediaQuery("(min-width:786px)");
  const profile = useSelector((state: RootState) => state.profile.data?.data);
// console.warn("profile",profile)
  // const dispatch  = useDispatch();
// const profileData = useSelector((state)=>state.profile.data);

  const dispatch = useDispatch<AppDispatch>();

  // console.log('userid',typeof userId);
  const decoded: any = jwtDecode(token);
  const userId = decoded.userId;
  // console.warn('id',id);
  useEffect(() => {
    if (userId) {
      dispatch(fetchProfile(userId));
    }
  }, [dispatch, userId]);
  // console.log('userId', userId)
  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  if (isMobile)
    return (
      <Box
        sx={{
          width: "auto",
          flexShrink: 0,
          height: "auto",
          backgroundColor: "#f0f4ff",
        }}
      >
        <Box sx={{ flexDirection: "column", flexGrow: 1 }}>
          <Box
            sx={{
              backgroundColor: "#f0f4ff",
              padding: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {!expanded ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>

                <SearchBar onClick={handleExpandClick}>
                  <SearchIconWrapper>
                    <Search />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search Designation, Status, Priority…"
                    inputProps={{ "aria-label": "search" }}
                  />
                </SearchBar>
                <Hidden xsDown>
                  <NotificationDrawer />
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    color="inherit"
                    sx={{ ml: 1 }}
                    onClick={handleProfileMenuOpen}
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src={profile?.profilePicture??"https://mui.com/static/images/avatar/1.jpg"}
                    />
                  </IconButton>
                  <ProfileMenu
                    anchorEl={anchorEl}
                    onClose={handleProfileMenuClose}
                  />
                </Hidden>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  margin: 0,
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "white",
                }}
              >
                <SearchBar
                  onClick={() => setExpanded(true)}
                  sx={{
                    width: "100%",
                    margin: 0,
                    borderWidth: 1,
                    borderColor: "red",
                  }}
                >
                  <SearchIconWrapper>
                    <Search />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search Designation, Status, Priority…"
                    inputProps={{ "aria-label": "search" }}
                    sx={{ width: "100%" }}
                  />
                </SearchBar>

                {/* close icon */}
                <CloseIcon
                  onClick={() => setExpanded(false)}
                  sx={{ width: "5%" }}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Button onClick={handleDrawerToggle}></Button>
        <Drawer open={mobileOpen} onClose={handleDrawerToggle}>
          <Sidebar />
        </Drawer>
      </Box>
    );

  return (
    <Box>
      {/* <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: '#f0f4ff' }}> */}
      {/* <Box sx={{ width: 'auto', flexShrink: 0 }}>
        {/* <Sidebar /> */}
      {/* </Box> */}

      <Box>
        <Box
          sx={{
            backgroundColor: "#f0f4ff",
            // position: "sticky",
            // top: 0,
            // zIndex:10,
            padding: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ flexDirection: "column" }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold ", fontSize: "18px" }}
            >
              Request To Hire
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: "300" }}>
                <Box
                  component="span"
                  sx={{ color: "text.primary", fontSize: "12px" }}
                >
                  Home
                </Box>
                <Box
                  component="span"
                  sx={{
                    color: "text.secondary",
                    marginLeft: "4px",
                    fontSize: "12px",
                  }}
                >
                  <Box component="span" sx={{ fontWeight: "bold" }}>
                    .
                  </Box>{" "}
                  Request to Hire
                </Box>
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SearchBar sx={{ width: "50%", backgroundColor: "white" }}>
              <SearchIconWrapper sx={{ paddingLeft: "5px" }}>
                <Search />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Designation, Status, Priority…"
                inputProps={{ "aria-label": "search" }}
              />
            </SearchBar>
            <Hidden xsDown>
              <NotificationDrawer />
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                color="inherit"
                sx={{ ml: 1 }}
                onClick={handleProfileMenuOpen}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={profile?.profilePicture??"https://mui.com/static/images/avatar/1.jpg"}
                  
                />
              </IconButton>
              <Box sx={{ ml: 2, textAlign: "center", fontSize: "8px" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {profile?.firstName}
                </Typography>
                <Typography variant="caption">{profile?.designation}</Typography>
              </Box>
              <ProfileMenu
                anchorEl={anchorEl}
                onClose={handleProfileMenuClose}
              />
            </Hidden>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderBar;
