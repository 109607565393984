import axios, { AxiosRequestConfig } from "axios";


interface ApiCallConfig extends AxiosRequestConfig {
  body?: any;
  contentType?: string;
  accessToken?: string;
  includeAuthorization?: boolean;
}

interface ApiResponse<T = any> {
  status: boolean;
  data: any;
  message?: T;
  error?: any;
}

export async function apiCall<T = any>(
  url: string,
  {
    body,
    method,
    contentType = "application/json",
    accessToken,
    includeAuthorization = true,
    ...customConfig
  }: ApiCallConfig = {}
): Promise<ApiResponse<T>> {
  const user = localStorage.getItem("token");
  const obj = user ? JSON.parse(user) : null;

  const config: AxiosRequestConfig = {
    url,
    method,
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": contentType,
      ...(includeAuthorization && { Authorization: `Bearer ${obj?.token}` }),
    },
    data: method === "GET" ? null : body,
    ...customConfig,
  };

  try {
    const response = await axios(config);
    const data: any = response.data;
    const { status, ...restData } = data || {};

    if (response.status === 200 && data?.statusMessage === "Success") {
      return {
        status: true,
        data: restData,
      };
    }

    return {
      status: false,
      data: restData,
      message: data?.status,
    };
  } catch (err: any) {
    if (err.response?.status === 401) {
      localStorage.removeItem("token");
    }
    return {
      status: false,
      data: err.data || null,
      message: err?.message,
      error: err,
    };
  }
}

apiCall.get = function <T = any>(url: string, customConfig: ApiCallConfig = {}): Promise<ApiResponse<T>> {
  return apiCall(url, { ...customConfig, method: "GET" });
};

apiCall.post = function <T = any>(url: string, body: any, customConfig: ApiCallConfig = {}): Promise<ApiResponse<T>> {
  return apiCall(url, { ...customConfig, method: "POST", body });
};

apiCall.put = function <T = any>(url: string, body: any, customConfig: ApiCallConfig = {}): Promise<ApiResponse<T>> {
  return apiCall(url, { ...customConfig, method: "PUT", body });
};

apiCall.delete = function <T = any>(url: string, body: any, customConfig: ApiCallConfig = {}): Promise<ApiResponse<T>> {
  return apiCall(url, { ...customConfig, method: "DELETE", body });
};
