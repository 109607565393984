import axios, { AxiosResponse, AxiosError } from "axios";

axios.interceptors.request.use(
  (config: any): any => {
    const token = localStorage.getItem("token");

    if (token) {
      if (config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },

  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response;
  },

  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  }
);
