import React from "react";
import { Box } from "@mui/material";
import Headergpt from "./headergpt";
import InternalOnRoleDetails from "./internalonroledetails";

const UxDesign: React.FC = () => {
  const positionTitle = "UX Design Architect";
  const status = "Open";
  const processSteps = [
    "Open",
    "Move to Job",
    "Create JD",
    "Job Post",
    "Uploading Resumes",
    "Screening",
    "Interviews",
    "Feedback",
    "Closed",
  ];
  const approvalStatus = { l1: "Approved", l2: "Pending" };

  const internalDetails = {
    business: "HEPL",
    positionTitle: "UX Designer",
    numberOfPositions: "02",
    department: "Digital IT",
    location: "Cuddalore",
    qualification: "B. Tech (CSE)",
    workMode: "Hybrid",
    experience: "3 yrs",
    band: "6B",
    ctc: "₹ 7,00,000",
    priority: "High",
    reportsTo: "Prabha",
    L1Approval: "Prabha",
    L2Approval: "Prabha",
    raisedBy: "Prabha",
    position: "Quality Assurence"
  };

  return (
    <Box sx={{ p: 3 }}>
      <Headergpt
        positionTitle={positionTitle}
        status={status}
        Steps={processSteps}
        approvalStatus={approvalStatus}
      />
      <InternalOnRoleDetails internalDetails={internalDetails} />
    </Box>
  );
};

export default UxDesign;
