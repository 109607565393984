import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile, updateProfile } from "../../redux/features/profileSlice";
import { RootState, AppDispatch } from "../../redux/store";
import {
  Box,
  Avatar,
  Typography,
  Grid,
  IconButton,
  TextField,
  Button,
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import deleteicon from "../../assets/images/deleteicon.svg";
import camera from "../../assets/images/camera.svg";
import avathar from "../../assets/images/Avathar2.png";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { profilePictureUpdate } from "../../Crud/profile";

const ProfilePage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const profile = useSelector((state: RootState) => state.profile.data?.data);
  const status = useSelector((state: RootState) => state.profile.status);
  // console.log('profile', profile)
  const token: any = localStorage.getItem("token");
  const [edit, setEdit] = useState(false);
  const [imageUrl,setImageUrl] = useState(profile?.profilePicture);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  // const [profilePic, setProfilePic] = useState(profile?.profilePicture);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    designation: "",
    location: "",
    profilePicture: "",
  });
console.log('formData', formData.profilePicture)
  const decoded: any = jwtDecode(token);
  const userId = decoded.userId;

  useEffect(() => {
    dispatch(fetchProfile(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (profile) {
      console.log('profile.profi', profile.profilePicture)
      setFormData({
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        designation: profile.designation,
        location: profile.location,
        profilePicture: profile.profilePicture,
      });
    }
  }, [profile]);
  // useEffect(()=>{
  //   setImageUrl(formData.profilePicture);
  
  // },[])
  const navigate = useNavigate();

  const handleEdit = () => {
    setEdit(!edit);
    setImageUrl("")

  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  console.log("profile", profile?.profilePicture);

  const handleUpdate = () => {
    console.log("formData", formData);
    // console.log("profilePic", profilePic);
    let imageData = {
      ...formData,
    };
    if (imageUrl) {
      imageData = {
        ...imageData,
        profilePicture: imageUrl,
      };
    }
    dispatch(updateProfile({ userId, profileData: imageData }));
    setEdit(false);
  };

  // const handleProfilePicChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   if (event.target.files && event.target.files[0]) {
  //     const file = event.target.files[0];
  //     const formData = new FormData();
  //     formData.append("image", file);
  
  //     // Update the profile picture using the formData
  //     profilePictureUpdate(userId, formData)
  //       .then((res) => console.log(res.data.data))
  //       .catch((err) => console.error(err));
  //   }
  // };
  // useEffect(()=>{

  // },)
  const handleProfilePicChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      const formData = new FormData();
      formData.append("image", file);

      // setProfilePic("")
      reader.onload = (e) => {
        // setProfilePic(e.target?.result as string);
        // const imageData = e.target?.result as string;
        profilePictureUpdate(userId,formData).then((res)=>setImageUrl(res.data.data)).catch(err=>console.error(err));
      };
      reader.readAsDataURL(file);
      // console.log("profilePic33", profilePic);
    }
  };

  // const handleProfilePicDelete = () => {
  //   setProfilePic("");
  // };
// console.log("imageUrl",imageUrl);
  const handleLogout = () => {
    setOpenLogoutDialog(true);
  };

  const handleCloseLogoutDialog = (confirm: boolean) => {
    setOpenLogoutDialog(false);
    if (confirm) {
      localStorage.removeItem("token");
      navigate("/login");
      console.log("User logged out");
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          backgroundColor: "#f0f4ff",
          //   height: "3px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: 2,
            p: 2,
            mr: 2,
            ml: 2,
            mt: 2,
            height: "450px",
          }}
        >
          <Box
            sx={{
              padding: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ flexDirection: "column" }}>
              <Typography variant="h5" sx={{ fontSize: "20px" }}>
                Profile
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ display: "flex", flexDirection: "row", color: "gray" }}
              >
                Dashboard | Profile
              </Typography>
            </Box>
            <Box>
              {!edit && (
                <IconButton
                  sx={{ color: "gray" }}
                  aria-label="edit"
                  onClick={handleEdit}
                >
                  <EditIcon sx={{ fontSize: "20px" }} />{" "}
                  <Typography p={1}>Edit</Typography>
                </IconButton>
              )}
              <IconButton
                sx={{ color: "gray" }}
                aria-label="logout"
                onClick={handleLogout}
              >
                <LogoutIcon sx={{ fontSize: "20px" }} />{" "}
                <Typography p={1}>Logout</Typography>
              </IconButton>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Avatar
              alt="Profile Picture"
              // src={profilePic}
              src={imageUrl?.length>=1?imageUrl: profile?.profilePicture??avathar}
              // src={
              //   profile?.profilePicture ??
              //   "https://mui.com/static/images/avatar/1.jpg"
              // }
              sx={{ width: 80, height: 80, mb: 0, mt: 3 }}
            />
            {edit && (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                  sx={{ paddingLeft: "10px" }}
                >
                  <label htmlFor="upload-photo">
                    <Input
                      sx={{ display: "none" }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      onChange={handleProfilePicChange}
                    />
                    <img
                      src={camera}
                      style={{ cursor: "pointer", marginBottom: "-3px" }}
                      alt="Camera Icon"
                    />
                  </label>
                  <img
                    src={deleteicon}
                    style={{ cursor: "pointer", padding: "10px" }}
                    alt="Delete Icon"
                    // onClick={handleProfilePicDelete}
                  />
                </Box>
              </>
            )}
          </Box>
          {status === "loading" && <p>Loading...</p>}
          {status === "failed" && <p>Error loading profile</p>}
          {status === "succeeded" && (
            <Box mt={13}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={3}>
                  {!edit ? (
                    <Box>
                      <Typography
                        variant="body1"
                        align="center"
                        sx={{ fontSize: "15px" }}
                      >
                        Employee name
                      </Typography>
                      <Typography
                        variant="h6"
                        align="center"
                        sx={{ fontSize: "15px" }}
                      >
                        {profile.firstName}
                      </Typography>
                    </Box>
                  ) : (
                    <TextField
                      label="First name"
                      name="firstName"
                      defaultValue={formData.firstName}
                      fullWidth
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </Grid>
                {/* <Grid item xs={12} md={2.4}>
                {!edit ? (
                  <Box>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{ fontSize: "15px" }}
                    >
                      Last name
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{ fontSize: "15px" }}
                    >
                      {profile.lastName}
                    </Typography>
                  </Box>
                ) : (
                  <TextField
                    label="Last name"
                    name="lastName"
                    defaultValue={formData.lastName}
                    fullWidth
                    onChange={handleInputChange}
                    variant="outlined"
                    size="small"
                  />
                )}
              </Grid> */}
                <Grid item xs={12} md={3}>
                  {!edit ? (
                    <Box>
                      <Typography
                        variant="body1"
                        align="center"
                        sx={{ fontSize: "15px" }}
                      >
                        Email
                      </Typography>
                      <Typography
                        variant="h6"
                        align="center"
                        sx={{ fontSize: "15px" }}
                      >
                        {profile.email}
                      </Typography>
                    </Box>
                  ) : (
                    <TextField
                      label="Email"
                      name="email"
                      defaultValue={formData.email}
                      fullWidth
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  {!edit ? (
                    <Box>
                      <Typography
                        variant="body1"
                        align="center"
                        sx={{ fontSize: "15px" }}
                      >
                        Designation
                      </Typography>
                      <Typography
                        variant="h6"
                        align="center"
                        sx={{ fontSize: "15px" }}
                      >
                        {profile.designation}
                      </Typography>
                    </Box>
                  ) : (
                    <TextField
                      label="Designation"
                      name="designation"
                      defaultValue={formData.designation}
                      fullWidth
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  {!edit ? (
                    <Box>
                      <Typography
                        variant="body1"
                        align="center"
                        sx={{ fontSize: "15px" }}
                      >
                        Location
                      </Typography>
                      <Typography
                        variant="h6"
                        align="center"
                        sx={{ fontSize: "15px" }}
                      >
                        {profile.location}
                      </Typography>
                    </Box>
                  ) : (
                    <TextField
                      label="Location"
                      name="location"
                      defaultValue={formData.location}
                      fullWidth
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
          {edit ? (
            <Box
              sx={{
                mt: 7,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Box p={1}>
                <Button
                  variant="contained"
                  sx={{ color: "gray", backgroundColor: "#f0f4ff" }}
                  onClick={handleEdit}
                >
                  Cancel
                </Button>
              </Box>
              <Box p={1}>
                <Button
                  variant="contained"
                  sx={{ color: "#F0F5FF" }}
                  onClick={handleUpdate}
                >
                  Update
                </Button>
              </Box>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>

      {/* Logout Confirmation Dialog */}
      <Dialog
        open={openLogoutDialog}
        onClose={() => handleCloseLogoutDialog(false)}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">
          {"Are you sure you want to logout?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            {}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => handleCloseLogoutDialog(false)}
            color="primary"
            sx={{ mr: "45px" }}
          >
            No, Cancel
          </Button>
          <Button
            onClick={() => handleCloseLogoutDialog(true)}
            color="primary"
            sx={{ mr: "35px" }}
            autoFocus
          >
            Yes, Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfilePage;
