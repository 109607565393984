import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import leftarrow from "../../../assets/images/leftarrow.svg";
import RthTab from "./RthTab";
import ContractTab from "./internal/contracttab";
import OnRoleTab from "./internal/onroletab";
import ExternalContract from "./external/ExternalContract";
import ExternalDetails from "./external/ExternalDetails";
import { useNavigate } from 'react-router-dom';

const NewRequestToHire: React.FC = () => {
  const [currentTab, setTab] = useState(1);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/dashboard'); 
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        backgroundColor: "#f0f4ff",
        // height: "100vh",
        width:"100%"
      }}
    >
      {/* <HeaderBar /> */}
      <Container sx={{ mt: 0, ml: 0 }}>
        {/* <RthTab /> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "17px",
          }}
        >
          <img src={leftarrow} alt="backbutton" style={{ cursor: 'pointer' }} onClick={handleButtonClick}/>
        </Box>

        <RthTab currentTab={currentTab} onChangeTab={setTab} />
        <Box>
          {currentTab === 1 && <OnRoleTab />}

          {currentTab === 2 && <ContractTab />}

          {currentTab === 3 && <ExternalContract />}

          {currentTab === 4 && <ExternalDetails />}
        </Box>
      </Container>
      {/* </Box> */}
    </Box>
  );
};

export default NewRequestToHire;
