import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    identifier: "",
    password: "",
  },
};

export const authSlice = createSlice({
  name: "authLogin",
  initialState,
  reducers: {
    authLoginGetData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { authLoginGetData } = authSlice.actions;
export default authSlice.reducer;
