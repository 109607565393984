import React from 'react';
import { Box, Typography } from '@mui/material';

interface JobHeaderProps {
  title?: string;
  tag?: string;
}

const JobHeader: React.FC<JobHeaderProps> = ({ title, tag }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: 'space-between', paddingTop: '5px', margin: "0 5px" }}>
      <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>{title}</Typography>
      <Typography sx={{ fontSize: '8px', color: '#08979C', padding: "3px 6px 0px 6px", backgroundColor: `${tag === "HEPL" ? "#D3ADF7" : '#B5F5EC'}`, borderRadius: '1px' }}>{tag}</Typography>
    </Box>
  );
}

export default JobHeader;
