import React from 'react';
import { Box } from '@mui/material';
import signupimg from '../assets/images/Login-page-layout.png';

const SignupImageBox: React.FC = () => {
  return (
    <Box
      flex={0.5}
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding={'1rem'}
      sx={{ position: 'relative' }}
    >
      <img src={signupimg} alt="Sign Up" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
    </Box>
  );
};

export default SignupImageBox;
