import React from 'react';
import { Box, Typography, Chip, IconButton } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import {
   
    Paper,
    Stepper,
    Step,
    StepLabel
  } from '@mui/material';

interface RequestToHireProps {
  positionTitle: string;
  status: string;
  Steps: string[];
  approvalStatus: { l1: string, l2: string };
}

const Headergpt: React.FC<RequestToHireProps> = ({
  positionTitle,
  status,
  Steps,
  approvalStatus
}) => {
  return (
    <Box sx={{ p: 2, backgroundColor: '#f0f4ff', borderRadius: 2 }}>
      {/* Top Section: Logo, Title, Status, Edit/Delete Icons */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          
          <Typography variant="h4" sx={{ flexGrow: 1, fontSize: '20px', fontWeight: 'Bold' }}>{positionTitle}</Typography>
          <Chip label={status} color={status === 'Open' ? 'success' : 'default'} sx={{ ml: 2 }} />
          <Chip label="Waiting for L1 & L2 Approval" sx={{ ml: 1, backgroundColor: '#f0f0f0', color: '#555' }} />
        </Box>
        <Box>
          <IconButton color="primary">
            <Edit />
          </IconButton>
          <IconButton color="secondary">
            <Delete />
          </IconButton>
        </Box>
      </Box>

      {/* RTH Process Steps */}
      
      
      <Paper elevation={1} sx={{ p: 2, backgroundColor: 'background.default' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row'}}>
      <Box sx={{width: '80%'}}>
        <Typography variant="subtitle2" sx={{ mb: 1, fontSize: '10px', color: "gray", ml: '10px' }}>
          RTH Process
        </Typography>
        <Stepper activeStep={0} alternativeLabel >
          {Steps.map((label) => (
            <Step key={label}>
              <StepLabel
                    StepIconProps={{
                      sx: { fontSize: '18px' }, 
                    }}
                    sx={{
                      '& .MuiStepLabel-label': {
                        fontSize: '9px', 
                      },
                    }}
                  >{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end',alignItems: 'center' }}>
        <Chip label={`L1 Approved`} color="success" sx={{ mr: 1 }} />
        <Chip label={`L2 Pending`} color="warning" />
      </Box>
      </Box>
      </Paper>
      

      {/* Approval Status */}
      
    
    </Box>
  );
};

export default Headergpt;
