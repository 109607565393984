import React from 'react';
import { Box, Typography, Link as MuiLink, Paper } from '@mui/material';
import theronimg from '../assets/images/theronimg.svg';
import {Link} from 'react-router-dom';

const ForgotPasswordEmail: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '90vh',
        backgroundColor: '#f0f4ff',
        padding: 2,
      }}
      
    >
      <Paper elevation={3} sx={{ maxWidth: 727, width: '100%', padding: 4 }}>
        <Box textAlign="start" mb={3}>
        <img src={theronimg} alt="Theron" style={{ height: 90 }} />
        </Box>
      
        <Typography variant="h6" component="h2" gutterBottom>
          Dear [Recipient's Name],
        </Typography>
        <Typography variant="body1" gutterBottom>
          We received a request to create your password. Kindly click the below link to create your password.
        </Typography>
        <Box textAlign="start" my={4}>
        <Link to="/createpasswordemail" style={{ textDecoration: 'none' }}>
                <MuiLink href="#" variant="button" color="primary">
                Create your Password
                </MuiLink>
        </Link>
        
        </Box>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          If you did not initiate this password reset request, please disregard this email. Rest assured,
          your account remains secure, and no changes have been made.
        </Typography>
        <Typography variant="body2" gutterBottom>
          Thank you,
        </Typography>
        <Typography variant="body2">
          THERON
        </Typography>
        
        <Typography variant="caption" color="textSecondary">
          Note: If you experience any difficulties during the password reset process or have any further questions,
          please do not hesitate to contact our support team at [Support Contact Details]. Our team will be more than happy to assist you.
        </Typography>
      </Paper>
    </Box>
  );
};

export default ForgotPasswordEmail;
