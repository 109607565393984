import React, { useState } from 'react';
import { Box, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import SearchBar from './SearchBar';
import ViewCard from '../ViewCard';
import { level1Data, level2Data, level3Data } from '../../common/JobCard';
import FilterSidebar from './FilterSidebar';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      // style={{ width:"100%",border:"2px solid blue" }}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export const TabsPanel: React.FC = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Box sx={{ width: "auto", backgroundColor: '#f0f4ff',display:"flex",position:"relative" }} p={1}>
      
      <FilterSidebar onChangeFilter={setDrawerOpen} drawerOpen={drawerOpen} />
      <Box>
      <Paper elevation={0} sx={{ backgroundColor: 'transparent', }}>
        <Tabs
          value={tabIndex}
          onChange={(e, newIndex) => setTabIndex(newIndex)}
          indicatorColor="primary"
          aria-label="job tabs"
          sx={{ ml:drawerOpen ? "0px" : "100px" }}
        >
          <Tab label="Waiting for the L1 & L2 Approvals" {...a11yProps(0) }sx={{ fontSize: '10px' }} />
          <Tab label="In Progress" {...a11yProps(1)}   sx={{ fontSize: '10px' }}/>
          <Tab label="Closed" {...a11yProps(2)}  sx={{ fontSize: '10px' }} />
  
        </Tabs>
      </Paper>
      <SearchBar />
      <CustomTabPanel value={tabIndex} index={0}>
        <Grid container spacing={2}  xs={16} sx={{ display: 'flex', flexWrap: "wrap", gap: '10px' }}>
          {/* {jobs.map((job, index) => (
          // <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <JobCard job={job} />
          // </Grid>
        ))} */}
          <ViewCard data={level1Data} />
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={1}>
        <Grid container spacing={2}  xs={16} sx={{ display: 'flex', flexWrap: "wrap", gap: '10px' }}>
          <ViewCard data={level2Data} />

          {/* {jobs.map((job, index) => (
          // <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <InProgress job={job} />
          // </Grid>
        ))} */}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={tabIndex} index={2}>
        <Grid container spacing={2}  xs={16} sx={{ display: 'flex', flexWrap: "wrap", gap: '10px' }}>
          {/* {closeJobs.map((job, index) => ( */}
          {/* // <Grid item xs={12} sm={6} md={4} lg={3} key={index}> */}
          <ViewCard data={level3Data} />

          {/* // </Grid> */}
          {/* ))} */}
        </Grid>

      </CustomTabPanel>
      </Box>
      {/* <Grid container display="flex" gap="15px" flexWrap="wrap" spacing={2} mt={2}>
        {jobs.map((job, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <JobCard job={job} />
          </Grid>
        ))}
      </Grid> */}
    </Box>
  );
};

export default TabsPanel;
