import React from 'react';
import { Box, Chip } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import pendingicon from '../../assets/images/pendingicon.svg';

const JobProgress: React.FC = () => {
  return (
    <Box display="flex" alignItems="center" mt={1} sx={{ paddingLeft: "2px" }}>
      <Chip
        label="L1 Approved"
        icon={<CheckCircleOutlineIcon style={{ color: '#4caf50', fontSize: '12px' }} />}
        sx={{ backgroundColor: '#F6FFED', color: '#595959', fontSize: '10px', border: "1px solid #D9F7BE", height: '24px' }}
      />
      <Box sx={{ width: "40px", backgroundColor: "#fff", height: "0px", border: "1px dashed #BFBFBF" }}></Box>
      <Chip
        label="L2 Pending"
        icon={<img src={pendingicon} alt='pending' style={{ width: '10px', height: '10px' }} />}
        sx={{ backgroundColor: '#FFF7E6', color: '#595959', fontSize: '10px', padding: '1px', margin: '1px', border: "1px solid #FFE7BA", height: '24px' }}
      />
    </Box>
  );
}

export default JobProgress;
