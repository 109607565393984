// src/redux/features/passwordSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

interface SetPasswordPayload {
  email: string;
  password: string;
  confirmPassword: string;
  token: string; 
}

interface PasswordState {
  isLoading: boolean;
  error: string | null;
  success: boolean;
}

export const setPassword = createAsyncThunk<void, SetPasswordPayload, { rejectValue: string }>(
  'password/setPassword',
  async ({ email, password, confirmPassword, token }, { rejectWithValue }) => {
    try {
      await axios.post(
        'https://mytheron-dev-backend.citpl.info/auth/setOrReSetPassword', 
        {
          email,
          password,
          confirmPassword
        },
  
      );
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data as string);
      }
      return rejectWithValue('An unexpected error occurred');
    }
  }
);

const initialState: PasswordState = {
  isLoading: false,
  error: null,
  success: false,
};

const passwordSlice = createSlice({
    name: 'password',
    initialState,
    reducers: {
      resetPasswordState: (state) => {
        state.isLoading = false;
        state.error = null;
        state.success = false;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(setPassword.pending, (state) => {
          state.isLoading = true;
          state.error = null;
          state.success = false;
        })
        .addCase(setPassword.fulfilled, (state) => {
          state.isLoading = false;
          state.success = true;
        })
        .addCase(setPassword.rejected, (state, action: PayloadAction<string | undefined>) => {
          state.isLoading = false;
          state.error = action.payload ?? 'An error occurred';
        });
    },
  });
  
  export const { resetPasswordState } = passwordSlice.actions;
  export default passwordSlice.reducer;
  
  