// src/App.js
import { BrowserRouter as Router } from "react-router-dom";
import AppRoute from "./routes";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
function App() {
  return (
    <>
      <Provider store={store}>
        <Router>
          <AppRoute />
        </Router>
      </Provider>
    </>
  );
}

export default App;
