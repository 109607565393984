import React from 'react';
import { Box, Button, InputAdornment, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import { useNavigate } from 'react-router-dom';

export const SearchBar: React.FC = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/newrth'); 
  };
  return (
    <Box display="flex" alignItems="center" padding={"20px"} mt={2} justifyContent="space-between" width={"auto"}>
      <InputBase
        placeholder="Search RTH..."
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        sx={{ border: '1px solid #ccc', borderRadius: 1, backgroundColor: 'white', minHeight:'3px',  fontSize:'12px' }}
      />
       <Box display="flex" alignItems="center" >
      <GridViewRoundedIcon sx={{ ml: 2 ,color:'#1677FF' }} />
      <FormatListBulletedIcon sx={{ ml: 1, mr: 2 , backgroundColor:'#000000 50%', backgroundSize:'10px'}} />
      <Button variant="contained" color="primary" 
      sx={{ fontSize: '10px' }} onClick={handleButtonClick} >
        {/* sx={{ mr: 5}} */}
        New Request to hire (RTH)
      </Button>
    </Box>
    </Box>
  );
};

export default SearchBar;
