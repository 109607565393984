import React, { useState } from "react";
import { Box, Grid, Typography, Button, Alert } from "@mui/material";
import CommonSelect from "../../../common/CommomSelect";
import { SelectChangeEvent } from "@mui/material";
import CommonTextField from "../../../common/CommonTextField";

const ContractTab: React.FC = () => {
  const [formData, setFormData] = useState({
    duration: "",
    type: "",
    business: "",
    position: "",
    l1Approval: "",
    l2Approval: "",
    department: "",
    band: "",
    qualification: "",
    minexperience: "",
    maxexperience: "",
    primarySkills: "",
    secondarySkills: "",
    workMode: "",
    priority: "",
    numPositions: "",
    reportsTo: "",
    reasonForHire: "",
    location: "",
    ctc: "",
    preferredStartDate: "",
    comments: "",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key as keyof typeof formData]) {
        newErrors[key] = "This field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   const newErrors = validate();
  //   if (Object.keys(newErrors).length > 0) {
  //     setErrors(newErrors);
  //     return;
  //   }
  //   setSubmitted(true);
  //   // Form is valid, proceed with submission
  // };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitted(true);

    if (validate()) {
      
      alert("Form submitted successfully!");
      
      setFormData({
        duration: "",
        type: "",
        business: "",
        position: "",
        l1Approval: "",
        l2Approval: "",
        department: "",
        band: "",
        qualification: "",
        minexperience: "",
        maxexperience: "",
        primarySkills: "",
        secondarySkills: "",
        workMode: "",
        priority: "",
        numPositions: "",
        reportsTo: "",
        reasonForHire: "",
        location: "",
        ctc: "",
        preferredStartDate: "",
        comments: "",
      });
      setErrors({});
      setSubmitted(false);
    }
    // const newErrors = validate();
    // setErrors(newErrors);
    // if (Object.keys(newErrors).length === 0) {
    //   setSubmitted(true);
    // } else {
    //   setSubmitted(false);
    // }
  };

  return (
    <>
     
      <Box component="form" onSubmit={handleSubmit}>
        <Box
          sx={{
            p: 3,
            backgroundColor: "white",
            marginTop: "10px",
            minWidth: "103%",
          }}
        >
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                md={2}
                sx={{ paddingTop: "33px", paddingLeft: "30px" }}
              >
                <CommonSelect
                  labelId="business-label"
                  label="Business"
                  name="business"
                  value={formData.business}
                  onChange={handleChange}
                  options={[
                    { value: "Business 1", label: "Business 1" },
                    { value: "Business 2", label: "Business 2" },
                  ]}
                  error={!!errors.business}
                  helperText={errors.business}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Position Title"
                  label="Position Title"
                  name="position"
                  value={formData.position}
                  onChange={handleChange}
                  options={[
                    { value: "position 1", label: "position 1" },
                    { value: "position 2", label: "position 2" },
                  ]}
                  error={!!errors.position}
                  helperText={errors.position}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonTextField
                  label="Number of Positions"
                  name="numPositions"
                  value={formData.numPositions}
                  onChange={handleChange}
                  error={!!errors.numPositions}
                  helperText={errors.numPositions}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonTextField
                  label="Position Reports To"
                  name="reportsTo"
                  value={formData.reportsTo}
                  onChange={handleChange}
                  error={!!errors.reportsTo}
                  helperText={errors.reportsTo}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="l1-approval-label"
                  label="L1 Approval"
                  name="l1Approval"
                  value={formData.l1Approval}
                  onChange={handleChange}
                  options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" },
                  ]}
                  error={!!errors.l1Approval}
                  helperText={errors.l1Approval}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="l2-approval-label"
                  label="L2 Approval"
                  name="l2Approval"
                  value={formData.l2Approval}
                  onChange={handleChange}
                  options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" },
                  ]}
                  error={!!errors.l2Approval}
                  helperText={errors.l2Approval}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonTextField
                  label="Reason for Hire"
                  name="reasonForHire"
                  value={formData.reasonForHire}
                  onChange={handleChange}
                  error={!!errors.reasonForHire}
                  helperText={errors.reasonForHire}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonTextField
                  label="Location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  error={!!errors.location}
                  helperText={errors.location}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Department"
                  label="Department"
                  name="department"
                  value={formData.department}
                  onChange={handleChange}
                  options={[
                    { value: "Developer", label: "Developer" },
                    { value: "Tester", label: "Tester" },
                  ]}
                  error={!!errors.department}
                  helperText={errors.department}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Band"
                  label="Band"
                  name="band"
                  value={formData.band}
                  onChange={handleChange}
                  options={[
                    { value: "position 1", label: "position 1" },
                    { value: "position 2", label: "position 2" },
                  ]}
                  error={!!errors.band}
                  helperText={errors.band}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Qualification Needed"
                  label="Qualification Needed"
                  name="qualification"
                  value={formData.qualification}
                  onChange={handleChange}
                  options={[
                    { value: "position 1", label: "position 1" },
                    { value: "position 2", label: "position 2" },
                  ]}
                  error={!!errors.qualification}
                  helperText={errors.qualification}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Min Experience"
                  label="Min Experience Required in years"
                  name="minexperience"
                  value={formData.minexperience}
                  onChange={handleChange}
                  options={[
                    { value: "position 1", label: "position 1" },
                    { value: "position 2", label: "position 2" },
                  ]}
                  error={!!errors.minexperience}
                  helperText={errors.minexperience}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Max Experience"
                  label="Max Experience Required in years"
                  name="maxexperience"
                  value={formData.maxexperience}
                  onChange={handleChange}
                  options={[
                    { value: "position 1", label: "position 1" },
                    { value: "position 2", label: "position 2" },
                  ]}
                  error={!!errors.maxexperience}
                  helperText={errors.maxexperience}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonTextField
                  label="CTC"
                  name="ctc"
                  value={formData.ctc}
                  onChange={handleChange}
                  error={!!errors.ctc}
                  helperText={errors.ctc}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Primary Skills"
                  label="Primary Skills"
                  name="primarySkills"
                  value={formData.primarySkills}
                  onChange={handleChange}
                  options={[
                    { value: "position 1", label: "position 1" },
                    { value: "position 2", label: "position 2" },
                  ]}
                  error={!!errors.primarySkills}
                  helperText={errors.primarySkills}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Secondary Skills"
                  label="Secondary Skills"
                  name="secondarySkills"
                  value={formData.secondarySkills}
                  onChange={handleChange}
                  options={[
                    { value: "position 1", label: "position 1" },
                    { value: "position 2", label: "position 2" },
                  ]}
                  error={!!errors.secondarySkills}
                  helperText={errors.secondarySkills}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonTextField
                  label="Preferred Start Date"
                  type="date"
                  name="preferredStartDate"
                  value={formData.preferredStartDate}
                  onChange={handleChange}
                  error={!!errors.preferredStartDate}
                  helperText={errors.preferredStartDate}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="work-mode-label"
                  label="Work Mode"
                  name="workMode"
                  value={formData.workMode}
                  onChange={handleChange}
                  options={[
                    { value: "Remote", label: "Remote" },
                    { value: "On-site", label: "On-site" },
                    { value: "Hybrid", label: "Hybrid" },
                  ]}
                  error={!!errors.workMode}
                  helperText={errors.workMode}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="priority-label"
                  label="Priority"
                  name="priority"
                  value={formData.priority}
                  onChange={handleChange}
                  options={[
                    { value: "High", label: "High" },
                    { value: "Medium", label: "Medium" },
                    { value: "Low", label: "Low" },
                  ]}
                  error={!!errors.priority}
                  helperText={errors.priority}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CommonTextField
                  label="Comments"
                  name="comments"
                  value={formData.comments}
                  onChange={handleChange}
                  error={!!errors.comments}
                  helperText={errors.comments}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            p: 3,
            backgroundColor: "white",
            marginTop: "10px",
            minWidth: "103%",
          }}
        >
          <Typography color="gray">Contract Details</Typography>
          <Box component="form" sx={{ mt: 1 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Contract Duration"
                  label="Contract Duration"
                  name="duration"
                  value={formData.duration}
                  onChange={handleChange}
                  options={[
                    { value: "3 month", label: "3 month" },
                    { value: "6 month", label: "6 month" },
                  ]}
                  error={!!errors.duration}
                  helperText={errors.duration}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Contract Type"
                  label="Contract Type"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  options={[
                    { value: "position 1", label: "position 1" },
                    { value: "position 2", label: "position 2" },
                  ]}
                  error={!!errors.type}
                  helperText={errors.type}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "flex-end",
            width: "106%",
          }}
        >
          <Button
            variant="contained"
            sx={{
              mr: 2,
              color: "black",
              backgroundColor: "transparent",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
            onClick={() => {
              setFormData({
                duration: "",
                type: "",
                business: "",
                position: "",
                l1Approval: "",
                l2Approval: "",
                department: "",
                band: "",
                qualification: "",
                minexperience: "",
                maxexperience: "",
                primarySkills: "",
                secondarySkills: "",
                workMode: "",
                priority: "",
                numPositions: "",
                reportsTo: "",
                reasonForHire: "",
                location: "",
                ctc: "",
                preferredStartDate: "",
                comments: "",
              });
              setErrors({});
              setSubmitted(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" sx={{ color: "#F0F5FF" }} type="submit">
            Submit
          </Button>
        </Box>
        {submitted && Object.keys(errors).length > 0 && (
        <Alert severity="error" sx={{ mt: 2 }}>
          Please correct the errors in the form before submitting.
        </Alert>
      )}
      </Box>
    </>
  );
};

export default ContractTab;
