import React from 'react';
import { Box, Typography } from '@mui/material';

import JobDetails from './JobDetails';
import JobFooter from './JobFooter';
import DummyProfile from '../../assets/images/close-card-image.png';
import ClosedImage from '../../assets/images/closedtext.svg';
import JobProgress from './JobProgress';
import JobTime from './JobTime';
import JobHeader from './JobHeader';

export const level1Data = [
  { title: 'UX Design Architect', type: 'Internal', status: 'On Role', posted: ' posted 10 days ago', tag: 'CITPL' },
  { title: 'Full Stack Developer', type: 'Internal', status: 'On Role', posted: ' posted 10 days ago', tag: 'CITPL' },
  { title: 'Business Analyst', type: 'Internal', status: 'On Role', posted: ' posted 10 days ago', tag: 'HEPL' },
  { title: 'Management Trainee', type: 'Internal', status: 'On Role', posted: ' posted 10 days ago', tag: 'CITPL' },
  // { title: 'Management Trainee', type: 'Internal', status: 'On Role', posted: ' posted 10 days ago', tag: 'CITPL' },
];
export const level2Data = [
  { title: 'UX Design Architect', type: 'Internal', status: 'On Role', moveTo: 'Moved to Job', posted: ' posted 10 days ago', tag: 'CITPL', image: DummyProfile, name: "Nithya Shree", reqName: "Senior Recruiter", tabStatus: "inprogress" },
  { title: 'Full Stack Developer', type: 'Internal', status: 'On Role', moveTo: 'Moved to Job', posted: ' posted 10 days ago', tag: 'CITPL', image: DummyProfile, name: "Nithya Shree", reqName: "Senior Recruiter", tabStatus: "inprogress" },
  { title: 'Business Analyst', type: 'Internal', status: 'On Role', moveTo: 'Moved to Job', posted: ' posted 10 days ago', tag: 'HEPL', image: DummyProfile, name: "Nithya Shree", reqName: "Senior Recruiter",tabStatus: "inprogress"  },
  { title: 'Management Trainee', type: 'Internal', status: 'On Role', moveTo: 'Moved to Job', posted: ' posted 10 days ago', tag: 'CITPL', image: DummyProfile, name: "Nithya Shree", reqName: "Senior Recruiter", tabStatus: "inprogress" },
];
export const level3Data = [
  { title: 'Product Head', type: 'Internal', status: 'On Role',  posted: ' posted 10 days ago', tag: 'CITPL', image: DummyProfile, name: "Nithya Shree", reqName: "Senior Recruiter", closeImage: ClosedImage, tabStatus: "closed" },
  { title: 'Product Head', type: 'Internal', status: 'On Role',  posted: ' posted 10 days ago', tag: 'CITPL', image: DummyProfile, name: "Nithya Shree", reqName: "Senior Recruiter", closeImage: ClosedImage, tabStatus: "closed" },
  { title: 'Product Head', type: 'Internal', status: 'On Role',  posted: ' posted 10 days ago', tag: 'CITPL', image: DummyProfile, name: "Nithya Shree", reqName: "Senior Recruiter", closeImage: ClosedImage, tabStatus: "closed" },
  { title: 'Product Head', type: 'Internal', status: 'On Role',  posted: ' posted 10 days ago', tag: 'CITPL', image: DummyProfile, name: "Nithya Shree", reqName: "Senior Recruiter", closeImage: ClosedImage, tabStatus: "closed" },
];
interface Job {
  title?: string;
  type?: string;
  status?: string;
  posted?: string;
  tag?: string;
  tabStatus?: string;
  image?: string;
  closeImage?: string;
  name?: string;
  reqName?: string;
  moveTo?:string;
}

interface JobCardProps {
  
  job?: Job;
  userName: string;
  userTitle: string;
  userAvatar: string;
}

const JobCard: React.FC<JobCardProps> = ({ job, userName, userTitle, userAvatar }) => (
  <Box sx={{ display: 'flex', flexWrap: "wrap", margin: 1, position: "relative" }}>
   <Box sx={{ backgroundColor: job?.tabStatus ==="closed" ? "#F6F6F6" : "white",  
       borderRadius: "10px", border: "1px solid lightgray", width: "250px", padding: "10px" }}>
      <JobHeader title={job?.title} tag={job?.tag} />

      <Box>

      </Box>
      <JobDetails type={job?.type} status={job?.status} moveTo={job?.moveTo} />
      <Box sx={{ display: 'flex', flexDirection: 'row', }}>
        <Box sx={{
          width: "100%",
          marginTop: "12px",
        }}>
          <JobTime posted={job?.posted} />
          {
            (job?.tabStatus === "closed"||job?.tabStatus === "inprogress" )?
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "20px",
                    paddingLeft: "2px"
                  }}
                >
                  <Box sx={{}}>
                    <img src={job.image} style={{ width: "25px", height: "25px" }} alt="jobcardimage"></img>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1,marginTop:"2px" }}>
                    <Typography variant='body2' sx={{ fontSize: '10px', lineHeight: 1.2 }}>{job.name}</Typography>
                    <Typography variant='caption' sx={{ fontSize: '8px', lineHeight: 1.2, color: 'gray' }}>{job.reqName}</Typography>
                  </Box>


                </Box>
                <Box sx={{ display: "inline", position: "absolute", top: 35, right: 15}}>
                  <img src={job.closeImage} style={{height:"95px"}} alt="jobcardimage"/>
                </Box>
              </Box> : 
              <>
              {
                job?.tabStatus === "open" ? 
                <JobFooter userName={userName} userTitle={userTitle} userAvatar={userAvatar} /> :
                <>
                <JobProgress/> 
                <JobFooter userName={userName} userTitle={userTitle} userAvatar={userAvatar} />
                </>
              }
                
              </>
          }
        </Box>
      </Box>
    </Box>
  </Box>
);

export default JobCard;
