import React from 'react';
import { Box, Grid, Typography, Chip, Avatar } from '@mui/material';
import Avathar from '../../../assets/images/Avathar3.png'

interface InternalDetailsProps {
  business: string;
  positionTitle: string;
  numberOfPositions: string;
  department: string;
  location: string;
  qualification: string;
  workMode: string;
  experience: string;
  band: string;
  ctc: string;
  priority: string;
  reportsTo: string;
  L1Approval: string;
  L2Approval: string;
  raisedBy: string;
  position: string;
}

interface InternalOnRoleDetailsProps {
  internalDetails: InternalDetailsProps;
}

const InternalOnRoleDetails: React.FC<InternalOnRoleDetailsProps> = ({ internalDetails }) => {
  return (
    <Box sx={{ p: 3, backgroundColor: 'background.default', borderRadius: 2, ml: "16px", mr:"16px"}}>
      <Typography variant="h6" sx={{ mb: 2 }}>Internal - On role Details</Typography>
      <Grid container spacing={2}>
        {/* Existing fields */}
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Business</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{internalDetails.business}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Position Title</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{internalDetails.positionTitle}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Number Of Positions</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{internalDetails.numberOfPositions}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Department</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{internalDetails.department}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Location</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{internalDetails.location}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Qualification Needed</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{internalDetails.qualification}</Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt='10px'>
        {/* Additional fields */}
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Work Mode</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{internalDetails.workMode}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Experience</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{internalDetails.experience}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Band</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{internalDetails.band}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>CTC</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>{internalDetails.ctc}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Priority</Typography>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>
              <Chip label={internalDetails.priority} color="error" />
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt='10px'>
        {/* New fields for Position Reports to, L1 Approval, L2 Approval, and Who Raised */}
        
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Position Reports To</Typography>
            <Box display="flex" alignItems="center" flexDirection={'row'}>
              <Box>
              <Avatar alt={internalDetails.reportsTo} src={Avathar} />
              </Box>
              <Box>
              <Typography variant="h6" sx={{ fontSize: "13px", ml: 1, mt: 1 }}>{internalDetails.reportsTo}</Typography>
              <Typography variant="h6" sx={{ fontSize: "11px", ml: 1, color: "gray" }}>{internalDetails.position}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>L1 Approval</Typography>
            <Box display="flex" alignItems="center" flexDirection={'row'}>
              <Box>
              <Avatar alt={internalDetails.L1Approval} src={Avathar} />
              </Box>
              <Box>
              <Typography variant="h6" sx={{ fontSize: "13px", ml: 1, mt: 1 }}>{internalDetails.L1Approval}</Typography>
              <Typography variant="h6" sx={{ fontSize: "11px", ml: 1, color: "gray" }}>{internalDetails.position}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>L2 Approval</Typography>
            <Box display="flex" alignItems="center" flexDirection={'row'}>
              <Box>
              <Avatar alt={internalDetails.L2Approval} src={Avathar} />
              </Box>
              <Box>
              <Typography variant="h6" sx={{ fontSize: "13px", ml: 1, mt: 1 }}>{internalDetails.L2Approval}</Typography>
              <Typography variant="h6" sx={{ fontSize: "11px", ml: 1, color: "gray" }}>{internalDetails.position}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Typography variant="body1" sx={{ fontSize: "12px", color: "gray" }}>Who Raised This Request</Typography>
            <Box display="flex" alignItems="center" flexDirection={'row'}>
              <Box>
              <Avatar alt={internalDetails.raisedBy} src={Avathar} />
              </Box>
              <Box>
              <Typography variant="h6" sx={{ fontSize: "13px", ml: 1, mt: 1 }}>{internalDetails.raisedBy}</Typography>
              <Typography variant="h6" sx={{ fontSize: "11px", ml: 1, color: "gray" }}>{internalDetails.position}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InternalOnRoleDetails;
