import React from "react";
import {
  Container,
  Box,
  Typography,
  Link,
  createTheme,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import emailicon from "../assets/images/emailicon.svg";
import bgimg from "../assets/images/login-pgimg.png";
import SignupImageBox from "./signupimgbox";
import leftArrow from "../assets/images/leftarrow.svg";

import { useNavigate } from "react-router-dom";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const CheckEmail: React.FC = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  
  const isXLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xl"
        sx={{ overflow: "hidden", backgroundColor: "#f0f4ff" }}
      >
        <Box
          display="flex"
          flexDirection={isSmallScreen || isMediumScreen ? 'column' : 'row'} height="100vh"
        >
          {!isSmallScreen && !isMediumScreen && <SignupImageBox />}
          <Box
            flex={isSmallScreen || isMediumScreen ? 'none' : 0.7}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundImage: `url(${bgimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              padding: isSmallScreen || isMediumScreen ? '1rem' : '3rem',
              // height: isSmallScreen || isMediumScreen ? '90%' : '84vh',
            }}
            minHeight={isSmallScreen || isMediumScreen ? "85vh" : "70vh"}
          >
            <Box
              sx={{
                width: isSmallScreen || isMediumScreen ? "100%" : "50%",
                maxWidth: isXLargeScreen ? 400 : 340,
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",

                p: 3,
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: 1,
              }}
            >
              <Box display="flex" justifyContent="center" mb={3}>
                <img src={emailicon} alt="Theron" style={{ height: 40 }} />
              </Box>
              <Typography
                component="h5"
                variant="h5"
                align="center"
                gutterBottom
              >
                Check your email
              </Typography>
              <Typography variant="body1" sx={{ color: "#8E8E8E" }}>
                We sent a password create link to
              </Typography>

              <Typography
                component="span"
                variant="body2"
                onClick={() => navigate("/forgotpasswordemail")}
                sx={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  my: 2,
                }}
              >
                example@gmail.com
              </Typography>

              <Typography variant="body2" sx={{ color: "#8E8E8E" }}>
                Didn't receive the email?{" "}
                <Link
                  href="#"
                  sx={{ color: "#3CB043", textDecoration: "none", my: 2 }}
                >
                  Resend
                </Link>
              </Typography>
              <Box mt={2}>
                <Typography
                  variant="body2"
                  onClick={() => navigate("/login")}
                  sx={{
                    cursor: "pointer",
                    color: "#8E8E8E",
                  }}
                >
                  <img src={leftArrow} alt="leftarrow" /> Back to Login
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default CheckEmail;
