import React from 'react';
import { FormControl, FormControlLabel, Checkbox, Typography, Button, Slider, TextField, Box, Divider, Collapse } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import NavigateNext from '@mui/icons-material/NavigateNext';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import { styled } from '@mui/system';


const SmallCheckbox = styled(Checkbox)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        fontSize: 12,
    },
}));

const CustomSmallFormControlLabel = (props: { label: string, number: string }) => (
    <FormControlLabel
        control={<SmallCheckbox sx={{ paddingLeft: '30px', borderColor: '#D9D9D9' }} />}
        label={
            <Box display="flex" justifyContent="space-between" width="100%">
                <Typography sx={{ fontSize: '12px', paddingRight: '100px' }}>{props.label}</Typography>
                <Typography sx={{ fontSize: '12px', marginLeft: 'auto', color: '#8C8C8C' }}>{props.number}</Typography>
            </Box>
        }
    />
);



const CustomSlider = styled(Slider)({
    color: '#73D13D',
    '& .MuiSlider-track': {
        background: 'linear-gradient(90deg, #73D13D 0%, #FFA940 50%, #FF4D4F 100%)',
    },
    '& .MuiSlider-rail': {
        opacity: 0.3,
        backgroundColor: '#bfbfbf',
    },
    '& .MuiSlider-thumb': {
        backgroundColor: '#fff',
        border: '2px solid currentColor',
    },
});

type Props = {
    drawerOpen: boolean;
    onChangeFilter: (value: boolean) => void
}

const FilterSidebar: React.FC<Props> = ({ drawerOpen, onChangeFilter }) => {

    const toggleDrawer = () =>
        onChangeFilter(!drawerOpen)



    return (
        <Box
            bgcolor={'white'}
            sx={{
                 margin: '20px', borderRadius: '10px', 
                width: drawerOpen ? "267px" : "50px", height: drawerOpen ? "250px" : "0px",
                position: drawerOpen ? "relative" : "absolute", zIndex: 1000,
                top:"-10px"
            }}
        >
            <Box sx={{ display: 'flex', bgcolor:"white",padding:"10px", justifyContent: 'space-between', alignItems: 'center', width: "91%", margin: '2px', borderRadius: '10px' }}>
            <TuneIcon sx={{ fontSize: 20 }} />

                {
                    drawerOpen &&
                    <Typography variant="h6"  sx={{ fontSize: 14 }} 
                    >
                        Filter
                    </Typography>
                }

                <Box sx={{ cursor: "pointer", padding: "0px", borderRadius: "3px", backgroundColor: '#F6F6F6', marginRight: "10px" }}>
                    {drawerOpen ? <NavigateBefore onClick={toggleDrawer} sx={{ fontSize: 14 }}/> : <NavigateNext onClick={toggleDrawer}  sx={{ fontSize: 14 }}/>}
                </Box>
            </Box>
            {drawerOpen &&
                <Divider />}

            <Collapse in={drawerOpen} timeout="auto" unmountOnExit orientation='horizontal'
            >
                <Box width={230} padding={1} bgcolor={"white"}>
                    <Box marginTop={2}>
                        <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px' }}>
                            L1 Status
                        </Typography>
                        <FormControl component="fieldset">
                            <CustomSmallFormControlLabel label="Pending" number="10" />
                            <CustomSmallFormControlLabel label="Approved" number="7" />
                            <CustomSmallFormControlLabel label="Declined" number="4" />
                        </FormControl>
                    </Box>



                    <Box marginTop={2}>
                        <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px' }}>L2 Status</Typography>
                        <FormControl component="fieldset">
                            <CustomSmallFormControlLabel label="Pending" number="10" />
                            <CustomSmallFormControlLabel label="Approved" number="7" />
                            <CustomSmallFormControlLabel label="Declined" number="4" />
                        </FormControl>
                    </Box>



                    <Box marginTop={2}>
                        <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px' }}>Job Kind</Typography>
                        <Box display="flex" alignItems="center" mt={1} sx={{ paddingLeft: '15px', gap: '8px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    width: 'auto',
                                    fontSize: '8px',
                                    height: '20px',
                                    color: '#2F54EB',
                                    borderColor: '#ADC6FF',
                                    backgroundColor: '#F0F5FF',
                                    padding: '4px 8px',
                                }}
                            >
                                Internal
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{
                                    width: 'auto',
                                    fontSize: '8px',
                                    height: '20px',
                                    color: '#BFBFBF',
                                    borderColor: '#8C8C8C',
                                    backgroundColor: '',
                                    padding: '6px 10px',
                                }}
                            >
                                On Role
                            </Button>
                        </Box>
                    </Box>



                    <Box marginTop={2}>
                        <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px' }}>Priority</Typography>
                        <CustomSlider
                            defaultValue={50}
                            aria-labelledby="discrete-slider"
                            step={10}
                        />
                        <Box display="flex" justifyContent="space-between">
                            <Typography sx={{ color: '#73D13D',fontSize: '10px' }}>Low</Typography>
                            <Typography sx={{ color: '#FFA940',fontSize: '10px' }}>Medium</Typography>
                            <Typography sx={{ color: '#FF4D4F',fontSize: '10px' }}>High</Typography>
                        </Box>
                    </Box>



                    <Box marginTop={2} alignItems='center'>
                        <Typography variant="subtitle1" sx={{ fontSize: '12px', fontWeight: 'bold', marginRight: '8px', paddingLeft: '10px' }}>Source Posted Date</Typography>
                        <TextField
                            type="date"
                            defaultValue="2024-03-10"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{
                                flexGrow: '1',
                                paddingLeft: '10px',
                                color: '#BFBFBF',
                                border: '##8C8C8C',
                                width: '200px',
                                '& .MuiInputBase-root': {
                                    height: '24px',
                                    fontSize:'10px'
                                },
                                '& .MuiInputBase-input': {
                                    padding: '10px',
                                },
                            }}
                        />
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
};

export default FilterSidebar;
