import axios from "axios";
import { PROFILE_API_URL } from "../constants/Crudurl";

export const profilePictureUpdate = async (id: string, formData: FormData) => {
  return axios.put(
    `${PROFILE_API_URL}/updateProfilePicture?id=${id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};