import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Signup from "../pages/signup";
import ForgotPassword from "../pages/forgotpassword";
import Login from "../pages/login";
import routesData from './routes.json';
import CheckEmail from "../pages/checkmail";
import ForgotPasswordEmail from "../pages/forgotpasswordemail";
import CreatePasswordEmail from "../pages/createpasswordemail";
import ResetEmail from "../pages/resetmail";
import ResetPasswordEmail from "../pages/resetpasswordmail";
import ResetPassword from "../pages/resetpassword";
import Dashboard from "../components/dashboard/Dashboard";
import NewRequestToHire from "../components/dashboard/Rth/newrth";
import ProfilePage from "../pages/profile/profilepage";
import HeaderBar from "../components/dashboard/commoncomponent/HeaderBar";
import { Box } from "@mui/material";
import Sidebar from "../components/dashboard/commoncomponent/SideBar";
import UxDesign from "../components/dashboard/UxArchitecture/uxdesignarchitect";
import UserMasterTable from "../components/admin/AdminTableView";
import SetPassword from "../pages/setpassword";

interface RouteConfig {
  path: string;
  component: string;
}

const componentMap: { [key: string]: React.FC } = {
  Login,
  Signup,
  ForgotPassword,
  CheckEmail,
  ForgotPasswordEmail,
  CreatePasswordEmail,
  ResetEmail,
  ResetPasswordEmail,
  ResetPassword,
  SetPassword,
  Dashboard,
  NewRequestToHire,
  ProfilePage,
  UxDesign,
  UserMasterTable,
};

const AppRoute: React.FC = () => {
  const location = useLocation();
  const hideSidebarAndHeader = [
    "/", "/login", "/signup", "/forgotpassword", "/checkemail", 
    "/forgotpasswordemail", "/createpasswordemail", "/resetemail", 
    "/resetpasswordemail", "/resetpassword", "/setpassword"
  ].includes(location.pathname);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: '#f0f4ff', height: '100vh' }}>
      {!hideSidebarAndHeader && (
        <Box sx={{ width: 'auto', flexShrink: 0 }}>
          <Sidebar />
        </Box>
      )}
      <Box sx={{ flexDirection: 'column', flexGrow: 1, overflowY: "hidden", height: '100vh' }}>
        {!hideSidebarAndHeader && <HeaderBar />}
        <Box sx={{ overflow: "auto", height: hideSidebarAndHeader ? "100%" : "calc(100vh - 64px)" }}>
          <Routes>
            {routesData.map((route: RouteConfig, index: number) => {
              const Component = componentMap[route.component];
              return <Route key={index} path={route.path} element={<Component />} />;
            })}
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

export default AppRoute;
