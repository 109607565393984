import React, { useState } from "react";
import { Box, Typography, Button, Grid, Alert } from "@mui/material";
import CommonSelect from "../../../common/CommomSelect";
import { SelectChangeEvent } from "@mui/material";
import CommonTextField from "../../../common/CommonTextField";

const ExternalContract: React.FC = () => {
  const [formData, setFormData] = useState({
    duration: "",
    type: "",
    business: "",
    position: "",
    department: "",
    band: "",
    qualification: "",
    minexperience: "",
    maxexperience: "",
    primarySkills: "",
    secondarySkills: "",
    workMode: "",
    priority: "",
    numPositions: "",
    reportsTo: "",
    location: "",
    preferredStartDate: "",
    comments: "",
    clientName: "",
    clientpoc: "",
    clientrc: "",
    marginAmount: "",
    marginPercentage: "",
    selectedFileName: "Choose file",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        selectedFileName: files[0].name,
      }));
    }
  };

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key as keyof typeof formData]) {
        newErrors[key] = "This field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitted(true);

    if (validate()) {
      // Form is valid, handle form submission
      alert("Form submitted successfully!");
      // Reset form
      setFormData({
        duration: "",
        type: "",
        business: "",
        position: "",
        department: "",
        band: "",
        qualification: "",
        minexperience: "",
        maxexperience: "",
        primarySkills: "",
        secondarySkills: "",
        workMode: "",
        priority: "",
        numPositions: "",
        reportsTo: "",
        location: "",
        preferredStartDate: "",
        comments: "",
        clientName: "",
        clientpoc: "",
        clientrc: "",
        marginAmount: "",
        marginPercentage: "",
        selectedFileName: "Choose file",
      });
      setErrors({});
      setSubmitted(false);
    }
  };

  return (
    <>
    <Box component="form" onSubmit={handleSubmit}>
      <Box
        sx={{
          p: 3,
          backgroundColor: "white",
          marginTop: "10px",
          minWidth: "103%",
        }}
      >
        {/* Main content goes here */}
        <Box component="form" sx={{ mt: 3 }}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={2}
              sx={{ paddingTop: "33px", paddingLeft: "30px" }}
            >
              <CommonSelect
                labelId="business-label"
                label="Business"
                name="business"
                value={formData.business}
                onChange={handleChange}
                options={[
                  { value: "Business 1", label: "Business 1" },
                  { value: "Business 2", label: "Business 2" },
                ]}
                error={!!errors.business}
                helperText={errors.business}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="Position Title"
                label="Position Title"
                name="position"
                value={formData.position}
                onChange={handleChange}
                options={[
                  { value: "position 1", label: "position 1" },
                  { value: "position 2", label: "position 2" },
                ]}
                error={!!errors.position}
                helperText={errors.position}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonTextField
                label="Number of Positions"
                name="numPositions"
                value={formData.numPositions}
                onChange={handleChange}
                error={!!errors.numPositions}
                helperText={errors.numPositions}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonTextField
                label="Internal SPOC"
                name="reportsTo"
                value={formData.reportsTo}
                onChange={handleChange}
                error={!!errors.reportsTo}
                helperText={errors.reportsTo}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonTextField
                label="Location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                error={!!errors.location}
                helperText={errors.location}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="Department"
                label="Department"
                name="department"
                value={formData.department}
                onChange={handleChange}
                options={[
                  { value: "Developer", label: "Developer" },
                  { value: "Tester", label: "Tester" },
                ]}
                error={!!errors.department}
                helperText={errors.department}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="Band"
                label="Band"
                name="band"
                value={formData.band}
                onChange={handleChange}
                options={[
                  { value: "position 1", label: "position 1" },
                  { value: "position 2", label: "position 2" },
                ]}
                error={!!errors.band}
                helperText={errors.band}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="Qualification Needed"
                label="Qualification Needed"
                name="qualification"
                value={formData.qualification}
                onChange={handleChange}
                options={[
                  { value: "position 1", label: "position 1" },
                  { value: "position 2", label: "position 2" },
                ]}
                error={!!errors.qualification}
                helperText={errors.qualification}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="Min Experience"
                label="Min Experience Required in years"
                name="minexperience"
                value={formData.minexperience}
                onChange={handleChange}
                options={[
                  { value: "position 1", label: "position 1" },
                  { value: "position 2", label: "position 2" },
                ]}
                error={!!errors.minexperience}
                helperText={errors.minexperience}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="Max Experience"
                label="Max Experience Required in years"
                name="maxexperience"
                value={formData.maxexperience}
                onChange={handleChange}
                options={[
                  { value: "position 1", label: "position 1" },
                  { value: "position 2", label: "position 2" },
                ]}
                error={!!errors.maxexperience}
                helperText={errors.maxexperience}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="Primary Skills"
                label="Primary Skills"
                name="primarySkills"
                value={formData.primarySkills}
                onChange={handleChange}
                options={[
                  { value: "position 1", label: "position 1" },
                  { value: "position 2", label: "position 2" },
                ]}
                error={!!errors.primarySkills}
                helperText={errors.primarySkills}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="Secondary Skills"
                label="Secondary Skills"
                name="secondarySkills"
                value={formData.secondarySkills}
                onChange={handleChange}
                options={[
                  { value: "position 1", label: "position 1" },
                  { value: "position 2", label: "position 2" },
                ]}
                error={!!errors.secondarySkills}
                helperText={errors.secondarySkills}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonTextField
                label="Preferred Start Date"
                type="date"
                name="preferredStartDate"
                value={formData.preferredStartDate}
                onChange={handleChange}
                error={!!errors.preferredStartDate}
                helperText={errors.preferredStartDate}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="work-mode-label"
                label="Work Mode"
                name="workMode"
                value={formData.workMode}
                onChange={handleChange}
                options={[
                  { value: "Remote", label: "Remote" },
                  { value: "On-site", label: "On-site" },
                  { value: "Hybrid", label: "Hybrid" },
                ]}
                error={!!errors.workMode}
                helperText={errors.workMode}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="priority-label"
                label="Priority"
                name="priority"
                value={formData.priority}
                onChange={handleChange}
                options={[
                  { value: "High", label: "High" },
                  { value: "Medium", label: "Medium" },
                  { value: "Low", label: "Low" },
                ]}
                error={!!errors.priority}
                helperText={errors.priority}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CommonTextField
                label="Comments"
                name="comments"
                value={formData.comments}
                onChange={handleChange}
                error={!!errors.comments}
                helperText={errors.comments}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          p: 3,
          backgroundColor: "white",
          marginTop: "10px",
          minWidth: "103%",
        }}
      >
        <Typography color="gray">Contract Details</Typography>
        {/* Main content goes here */}
        <Box component="form" sx={{ mt: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="Contract Duration"
                label="Contract Duration"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                options={[
                  { value: "3 month", label: "3 month" },
                  { value: "6 month", label: "6 month" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="Contract Type"
                label="Contract Type"
                name="type"
                value={formData.type}
                onChange={handleChange}
                options={[
                  { value: "position 1", label: "position 1" },
                  { value: "position 2", label: "position 2" },
                ]}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          p: 3,
          backgroundColor: "white",
          marginTop: "10px",
          minWidth: "103%",
        }}
      >
        <Typography color="gray">ExternalDetails</Typography>
        {/* Main content goes here */}
        <Box component="form" sx={{ mt: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={2}>
              <CommonTextField
                label="Client Name"
                name="clientName"
                value={formData.clientName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="Client POC"
                label="Client POC"
                name="clientpoc"
                value={formData.clientpoc}
                onChange={handleChange}
                options={[
                  { value: "3 month", label: "3 month" },
                  { value: "6 month", label: "6 month" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <input
                accept=".pdf,.doc,.docx"
                id="file-input"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <label htmlFor="file-input">
                <Button
                  variant="outlined"
                  component="span"
                  sx={{
                    minWidth: "168px",
                    fontSize: "0.75rem",
                    padding: "2px 4px",
                    minHeight: "40px",
                    border: "1px solid",
                    borderColor: "darkgray",
                    borderRadius: "4px",
                    marginTop: "1px",
                    backgroundColor: "transparent",
                  }}
                  // sx={{ textTransform: "none" }}
                >
                  {formData.selectedFileName}
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonSelect
                labelId="Client Rate Card"
                label="Client Rate Card"
                name="clientrc"
                value={formData.clientrc}
                onChange={handleChange}
                options={[
                  { value: "3 month", label: "3 month" },
                  { value: "6 month", label: "6 month" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonTextField
                label="Client Margin in Amount"
                name="marginAmount"
                value={formData.marginAmount}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <CommonTextField
                label="Client margin%"
                name="marginPercentage"
                value={formData.marginPercentage}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "flex-end",
          width: "106%",
        }}
      >
        <Button
          variant="contained"
          sx={{
            mr: 2,
            color: "black",
            backgroundColor: "transparent",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "transparent", // Ensure hover doesn't add background color
              boxShadow: "none",
            },
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" sx={{ color: "#F0F5FF" }} type="submit">
            Submit
          </Button>
      </Box>
      {submitted && Object.keys(errors).length > 0 && (
        <Alert severity="error" sx={{ mt: 2 }}>
          Please correct the errors in the form before submitting.
        </Alert>
      )}
      </Box>
    </>
  );
};

export default ExternalContract;
