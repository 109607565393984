// CustomPaper.tsx
import React from "react";
import { Paper, Tabs, Tab } from "@mui/material";

interface CustomPaperProps {
  tabIndex: number;
  onTabChange: (event: React.SyntheticEvent, newIndex: number) => void;
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const MasterTab: React.FC<CustomPaperProps> = ({ tabIndex, onTabChange }) => {
  return (
    <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
      <Tabs
        value={tabIndex}
        onChange={onTabChange}
        indicatorColor="primary"
        aria-label="job tabs"
        TabIndicatorProps={{
          style: {
            width: "20px",
            marginLeft: `${tabIndex === 0 ? "50px" : "36px"}`,
          },
        }}
      >
        <Tab label="User Master" {...a11yProps(0)} />
        <Tab label="Master 2" {...a11yProps(1)} />
        <Tab label="Master 3" {...a11yProps(2)} />
      </Tabs>
    </Paper>
  );
};

export default MasterTab;
