import React, { useState } from 'react';
import { Drawer, List, ListItem, IconButton, Hidden, Box } from '@mui/material';
import { Menu } from '@mui/icons-material';
import theronlogo from '../../../assets/images/theronlogo.svg';
import one from '../../../assets/images/one.svg';
import two from  '../../../assets/images/two.svg';
import three from '../../../assets/images/three.svg';
import four from '../../../assets/images/four.svg';
import five from '../../../assets/images/five.svg';

const drawerWidth = 64;

const Sidebar: React.FC = () => {
    const [isMobileOpen, setIsMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setIsMobileOpen(!isMobileOpen);
    };

    const drawerContent = (
        <List sx={{ padding: 0 }}>
            <ListItem sx={{ justifyContent: 'center', padding: '10px 0' }}>
                <img src={theronlogo} alt='logo' style={{ maxWidth: '40px', maxHeight: '40px' }} />
            </ListItem>
            <ListItem sx={{ justifyContent: 'center', padding: '10px 0' }}>
                <img src={one} alt='one' style={{ maxWidth: '40px', maxHeight: '40px' }} />
            </ListItem>
            <ListItem sx={{ justifyContent: 'center', padding: '10px 0' }}>
                <img src={two} alt='two' style={{ maxWidth: '40px', maxHeight: '40px' }} />
            </ListItem>
            <ListItem sx={{ justifyContent: 'center', padding: '10px 0' }}>
                <img src={three} alt='three' style={{ maxWidth: '40px', maxHeight: '40px' }} />
            </ListItem>
            <ListItem sx={{ justifyContent: 'center', padding: '10px 0' }}>
                <img src={four} alt='four' style={{ maxWidth: '40px', maxHeight: '40px' }} />
            </ListItem>
            <ListItem sx={{ justifyContent: 'center', padding: '10px 0' }}>
                <img src={five} alt='five' style={{ maxWidth: '40px', maxHeight: '40px' }} />
            </ListItem>
        </List>
    );

    return (
        <Box sx={{ display: 'flex',width:64 }}>
            <Hidden smUp>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ marginLeft: 2, marginTop: 2 }}
                >
                    <Menu />
                </IconButton>
                <Drawer
                    variant="temporary"
                    open={isMobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, 
                    }}
                    sx={{
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: 'linear-gradient(180deg, #36CFC9, #2F54EB)' },
                    }}
                >
                    {drawerContent}
                </Drawer>
            </Hidden>
            <Hidden xsDown>
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', background: 'linear-gradient(180deg, #36CFC9, #2F54EB)' },
                    }}
                >
                    {drawerContent}
                </Drawer>
            </Hidden>
        </Box>
    );
};

export default Sidebar;
