import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { addUser, fetchUsers } from "../../redux/features/userSlice";
import { AppDispatch } from "../../redux/store/index";

interface AddUserFormProps {
  open: boolean;
  onClose: () => void;
}

export interface UserData {
  id?: string;
  firstName: string;
  email: string;
  mobNumb: string;
  organization: string;
  designation: string;
  role: string;
  department: string;
  supervisor: string;
  profilePicture: string;
}

const AddUserForm: React.FC<AddUserFormProps> = ({ open, onClose }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [formData, setFormData] = useState<Partial<UserData>>({
    firstName: "",
    email: "",
    mobNumb: "",
    organization: "",
    designation: "",
    role: "",
    department: "",
    supervisor: "",
  });

  const [formErrors, setFormErrors] = useState<Partial<Record<keyof UserData, string>>>({});
  const [loading, setLoading] = useState(false);

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateField(name as keyof UserData, value);
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateField(name as keyof UserData, value);
  };

  const validateField = (fieldName: keyof UserData, value: string) => {
    let error = "";
    if (!value) {
      error = "This field is required";
    } else if (fieldName === "email" && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = "Invalid email address";
    } else if (fieldName === "mobNumb" && !/^\d{10}$/.test(value)) {
      error = "Invalid mobile number";
    }

    setFormErrors((prevErrors) => ({ ...prevErrors, [fieldName]: error }));
  };

  // const handleSubmit = async () => {
  //   const hasErrors = Object.keys(formData).some((key) => {
  //     validateField(key as keyof UserData, formData[key as keyof UserData] || "");
  //     return formErrors[key as keyof UserData];
  //   });
  
  //   if (!hasErrors) {
  //     try {
  //       await dispatch(addUser(formData)).unwrap();
  //       await dispatch(fetchUsers()).unwrap();
  //       onClose();
  //       setFormData({
  //         firstName: "",
  //         email: "",
  //         mobNumb: "",
  //         organization: "",
  //         designation: "",
  //         role: "",
  //         department: "",
  //         supervisor: "",
  //       });
  //     } catch (error) {
  //       console.error("Failed to add the user: ", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  // };


  const handleSubmit = async () => {
    // Validate all fields
    const newFormErrors: Partial<Record<keyof UserData, string>> = {};
  
    Object.keys(formData).forEach((key) => {
      const value = formData[key as keyof UserData] || "";
      validateField(key as keyof UserData, value);
      if (!value) {
        newFormErrors[key as keyof UserData] = "This field is required";
      }
    });
  
    setFormErrors(newFormErrors);
  
    // Check if there are any validation errors
    const hasErrors = Object.values(newFormErrors).some((error) => error !== "");
  
    if (hasErrors) {
      return; // Don't submit the form if there are errors
    }
  
    setLoading(true);
    try {
      await dispatch(addUser(formData)).unwrap();
      await dispatch(fetchUsers()).unwrap();
      onClose();
      setFormData({
        firstName: "",
        email: "",
        mobNumb: "",
        organization: "",
        designation: "",
        role: "",
        department: "",
        supervisor: "",
      });
    } catch (error) {
      console.error("Failed to add the user: ", error);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Add User
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box component="form" noValidate autoComplete="off" sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Employee Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleTextFieldChange}
                variant="outlined"
                size="small"
                error={Boolean(formErrors.firstName)}
                helperText={formErrors.firstName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Mail ID"
                name="email"
                value={formData.email}
                onChange={handleTextFieldChange}
                variant="outlined"
                size="small"
                error={Boolean(formErrors.email)}
                helperText={formErrors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Mobile Number"
                name="mobNumb"
                value={formData.mobNumb}
                onChange={handleTextFieldChange}
                variant="outlined"
                size="small"
                error={Boolean(formErrors.mobNumb)}
                helperText={formErrors.mobNumb}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" size="small" error={Boolean(formErrors.organization)}>
                <InputLabel>Organization</InputLabel>
                <Select
                  name="organization"
                  value={formData.organization}
                  onChange={handleSelectChange}
                  label="Organization"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Org1">Org1</MenuItem>
                  <MenuItem value="Org2">Org2</MenuItem>
                  <MenuItem value="Org3">Org3</MenuItem>
                </Select>
                <FormHelperText>{formErrors.organization}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" size="small" error={Boolean(formErrors.designation)}>
                <InputLabel>Designation</InputLabel>
                <Select
                  name="designation"
                  value={formData.designation}
                  onChange={handleSelectChange}
                  label="Designation"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Designation1">Designation1</MenuItem>
                  <MenuItem value="Designation2">Designation2</MenuItem>
                  <MenuItem value="Designation3">Designation3</MenuItem>
                </Select>
                <FormHelperText>{formErrors.designation}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" size="small" error={Boolean(formErrors.supervisor)}>
                <InputLabel>Supervisor</InputLabel>
                <Select
                  name="supervisor"
                  value={formData.supervisor}
                  onChange={handleSelectChange}
                  label="Supervisor"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Supervisor1">Supervisor1</MenuItem>
                  <MenuItem value="Supervisor2">Supervisor2</MenuItem>
                  <MenuItem value="Supervisor3">Supervisor3</MenuItem>
                </Select>
                <FormHelperText>{formErrors.supervisor}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" size="small" error={Boolean(formErrors.role)}>
                <InputLabel>Role</InputLabel>
                <Select
                  name="role"
                  value={formData.role}
                  onChange={handleSelectChange}
                  label="Role"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Role1">Role1</MenuItem>
                  <MenuItem value="Role2">Role2</MenuItem>
                  <MenuItem value="Role3">Role3</MenuItem>
                </Select>
                <FormHelperText>{formErrors.role}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" size="small" error={Boolean(formErrors.department)}>
                <InputLabel>Department</InputLabel>
                <Select
                  name="department"
                  value={formData.department}
                  onChange={handleSelectChange}
                  label="Department"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Department1">Department1</MenuItem>
                  <MenuItem value="Department2">Department2</MenuItem>
                  <MenuItem value="Department3">Department3</MenuItem>
                </Select>
                <FormHelperText>{formErrors.department}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleSubmit} variant="contained" color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserForm;
