import React from 'react';
import { TextField } from '@mui/material';

interface CommonTextFieldProps {
  label: string;
  value: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  multiline?: boolean;
  rows?: number;
  size?: 'small' | 'medium';
  error?: boolean;
  helperText?: string;
}

const CommonTextField: React.FC<CommonTextFieldProps> = ({ label, value, name, onChange, type = 'text', multiline = false, rows = 1, size = 'small', error = false, helperText = '' }) => {
  return (
    <TextField
      fullWidth
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      type={type}
      multiline={multiline}
      rows={rows}
      size={size}
      variant="outlined"
      error={error} // Ensure error prop is passed correctly
      helperText={helperText} // Ensure helperText prop is passed correctly
      InputLabelProps={{
        sx: { 
          fontSize: '0.9rem',
          transform: 'translate(14px, 12px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          }
        },
        shrink: type === 'date' || value.length > 0,
        // shrink: true,
      }}
      
    />
  );
};


export default CommonTextField;
