import React from 'react';
import { Box } from '@mui/material';
import JobCard from '../common/JobCard';

export const cardview = {
  display: 'flex',
  flexWrap: 'wrap',
};

function ViewCard ({data}:any){ 
  return(
  <Box sx={cardview}>
    {data?.map((job:any, index:number) => (
      
      <JobCard
        key={index}
        job={job}
        userName="Nithya Shree"
        userTitle="Senior Recruiter"
        userAvatar="https://mui.com/static/images/avatar/3.jpg"
        
      />
      
    ))}
  </Box>
)};

export default ViewCard;
