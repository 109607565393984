
import React from 'react';
import { Container, Box, Typography, TextField, Button, Link as MuiLink, createTheme, ThemeProvider, useMediaQuery } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import {Link, useNavigate } from 'react-router-dom';
import theronimg from '../assets/images/theronimg.svg';
import bgimg from '../assets/images/login-pgimg.png';
import SignupImageBox from './signupimgbox';


const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

interface SignupFormInputs {
  firstName: string;
  lastName: string;
  email: string;
}

const Signup: React.FC = () => {
  const { handleSubmit, control, reset, formState: { errors } } = useForm<SignupFormInputs>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: ''
    }
  });

  const navigate = useNavigate();

  const onSubmit = (data: SignupFormInputs) => {
    console.log(data);
    reset();
    navigate('/checkemail');
  };

  

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl" sx={{backgroundColor: '#f0f4ff'}}>
        <Box display="flex" flexDirection={isSmallScreen || isMediumScreen ? 'column' : 'row'} height="100vh">
        {!isSmallScreen && !isMediumScreen && <SignupImageBox />}
          <Box
            flex={isSmallScreen || isMediumScreen ? 'none' : 0.7}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundImage: `url(${bgimg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              padding: isSmallScreen || isMediumScreen ? '1rem' : '3rem',
              height: isSmallScreen || isMediumScreen ? '90%' : '84vh',
            }}
          >
            <Box sx={{
                width: isSmallScreen ? '100%' : '50%',
                maxWidth: isXLargeScreen ? 400 : 340,
                display: 'flex',
                flexDirection: 'column',
                // alignItems: 'center',
                justifyContent: 'center',
                minHeight: '85vh' 
             }}>
              <Box display="flex" justifyContent="center" mb={isSmallScreen ? 1 : 3}>
                <img src={theronimg} alt="Theron" style={{ height: isXLargeScreen ? 120 : 90, marginBottom: '1rem' }} />
              </Box>
              <Typography component="h6" variant={isLargeScreen || isXLargeScreen ? "h5" : "h6"} alignItems="start">
                Sign Up
              </Typography>
              <Box component="form" sx={{ mt: 3, marginTop:'5px' }} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: 'First Name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoComplete="fname"
                      autoFocus
                      error={Boolean(errors.firstName)}
                      helperText={errors.firstName?.message}
                      InputProps={{ sx: { borderRadius: 2, backgroundColor: 'white' } }}
                      size="small"
                    //   sx={{ borderRadius: 3, bgcolor: 'background.paper' }}
                    />
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: 'Last Name is required' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      autoComplete="lname"
                      error={Boolean(errors.lastName)}
                      helperText={errors.lastName?.message}
                      InputProps={{ sx: { borderRadius: 2, backgroundColor: 'white' } }}
                      size="small"
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  rules={{ 
                    required: 'Email is required',
                    pattern: {
                      value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                      message: 'Enter a valid email'
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email Address"
                      autoComplete="email"
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                      InputProps={{ sx: { borderRadius: 2, backgroundColor: 'white' } }}
                      size="small"
                    />
                  )}
                />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, borderRadius: 2, background: 'linear-gradient(90deg, #36CFC9, #2F54EB)' }}>
                  Sign Up
                </Button>
                <Box textAlign="center">
                <Link to="/login" style={{ textDecoration: 'none' }}>
                <MuiLink href="#" variant="body2">
                      Back to Login
                </MuiLink>
              </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Signup;
